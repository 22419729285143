.blurb-name{
    height: 20px;
    align-items: center;
    display: flex;
    margin: 5px;

    &__mobile{
        letter-spacing: 6px;
    }

    &__desktop{
        letter-spacing: 12px;
    }
}

.blurb-attribute{
    height: 20px;
    align-items: center;
    display: flex;
    margin: 5px;
    padding: 2px 20px 2px 20px;

    &__day{
        background-color: #4c4c4c;
    }

    &__night{
        background-color: #ffffffcf;
    }
    // &__night:hover{
    //     color: #fff !important;
    //     background-color: #4c4c4c;
    //     cursor: crosshair;
    // }
}