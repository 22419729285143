body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.show-zoom-animation{
    
  animation: show 0.5s 1;
  -webkit-animation: show 0.5s 1;
  animation-fill-mode: forwards;
}
@keyframes show{
  from {opacity :0; transform: scale(1.05);}
  to {opacity :1;transform: scale(1.0);}
}
