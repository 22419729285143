

.link {
    color: #939393;
    transition: color 200ms ease-in-out;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
}

.link:hover {
    color: #fff;
    transition: color 200ms ease-in-out;
}

.link-bright {
    color: #383838ab;
    transition: color 200ms ease-in-out;
    font-family: 'Raleway', sans-serif;
    font-weight: 700;
}

.link-bright:hover {
    color: #000000;
    transition: color 200ms ease-in-out;
}

.navigation-button:focus {
    background-color: #939393;
}

.color-transition{
    transition: color 200ms ease-in-out, background-color 200ms ease-in-out;
}