.text-color-animation-night {
    background: linear-gradient(45deg, #fff, #707070, #fff);
}

.text-color-animation-day {
    background: linear-gradient(45deg, #fff, #707070, #fff);
}

.text-color-animation {
    background-clip: text !important;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
    background-size: 400% 400%;
    animation: gradient 15s ease-in-out infinite;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.text-color-animation-fast {
    background-clip: text !important;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
    background: linear-gradient(45deg, #fff, #333333, #fff);
    background-size: 400% 400%;
    animation: gradient-fast 1.5s ease-in-out 1;
}

@keyframes gradient-fast {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}